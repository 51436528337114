<template>
  <!-- HORIZONTAL LAYOUT -->
  <vx-card title="Show Product Festives">
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
        >Back</vs-button
      >
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>TOP Promo Code </span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-input class="w-full" v-model="code" disabled />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Description </span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-input class="w-full" v-model="description" disabled />
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>SO Type</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedSoType"
              :options="optionSoType"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              disabled
              :custom-label="customLableSoType"
            />
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>TOP Model</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedTopModel"
              :options="OptionTopModel"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="value"
              label="name"
              disabled
            />
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>TOP Value</span>
          </div>
          <div
            v-if="selectedTopModel.value === 'TOP'"
            class="vx-col sm:w-3/4 w-full"
          >
            <multiselect
              class="selectExample"
              v-model="selectedTOP"
              :options="optionTOP"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              disabled
              @search-change="handlerSearchChangeTOP"
              :custom-label="customLableTOP"
            />
          </div>
          <div
            v-if="selectedTopModel.value === 'FDD'"
            class="vx-col sm:w-3/4 w-full"
          >
            <vs-input
              type="date"
              class="w-full"
              v-validate="'required'"
              name="Date"
              v-model="date"
              disabled
            />
            <span class="text-danger text-sm" v-show="errors.has('Date')">{{
              errors.first("Date")
            }}</span>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Supplier</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedSupplier"
              :options="optionSupplier"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              disabled
              @search-change="handleSearchSupplier"
              :custom-label="customLableSupplier"
              @input="SupplierInput"
            />
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Valid From</span>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input
              type="date"
              class="w-full"
              v-validate="'required'"
              name="ValidFrom"
              v-model="validFrom"
              disabled
              :min="new Date().toISOString().slice(0, 10)"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('ValidFrom')"
              >{{ errors.first("ValidFrom") }}</span
            >
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <span>Valid To</span>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input
              type="date"
              class="w-full"
              v-validate="'required'"
              name="ValidTo"
              v-model="validTo"
              disabled
              :min="new Date().toISOString().slice(0, 10)"
            />
            <span class="text-danger text-sm" v-show="errors.has('ValidTo')">{{
              errors.first("ValidTo")
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Create By</span>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input
              type="input"
              class="w-full"
              v-model="createdBy"
              disabled
            />
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <span>Date Create</span>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input
              type="input"
              class="w-full"
              v-model="createdAt"
              disabled
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Approved By</span>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input
              type="input"
              class="w-full"
              v-model="approvedBy"
              disabled
            />
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <span>Date Approved</span>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input
              type="input"
              class="w-full"
              v-model="dateApproved"
              disabled
            />
          </div>
        </div>

        <div class="vx-row mb-6" style="width: 70%">
          <div class="vx-col sm:w-1/3 mr-5 w-full">
            <span>Status</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-switch width="20px" color="success" v-model="status" disabled>
              <span slot="on">Active</span>
              <span slot="off">Inactive</span>
            </vs-switch>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Notes</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <vs-textarea
              v-model="notes"
              class="w-full"
              rows="3"
              name="notes"
              disabled
            ></vs-textarea>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Attachment</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <vs-input
              type="file"
              class="w-full"
              v-model="attachment"
              name="attachment"
              disabled
            />
          </div>
        </div>

        <!-- <div class="vx-row">
            <div class="vx-col sm:w-6/12 w-full" v-if="update">
              <vs-button class="mr-3 mb-2" v-on:click="SubmitForm()"
                >Submit</vs-button
              >
            </div>
          </div> -->
      </div>
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedTerritory"
              :options="optionTerritory"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              disabled
              @search-change="handlerSearchChangeT"
              :custom-label="customLableTerritory"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span> Distribution Channel </span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedDistributionChannel"
              :options="optionDistributionChannel"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              disabled
              @search-change="handlerSearchChangeCC"
              :custom-label="customLableDistributionChannel"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer Group 1 </span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomerGroup1"
              :options="optionCustomerGroup1"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              disabled
              @search-change="handlerSearchChangeCG1"
              :custom-label="customLableCustomerGroup1"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer Group 2</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomerGroup2"
              :options="optionCustomerGroup2"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              disabled
              @search-change="handlerSearchChangeCG2"
              :custom-label="customLableCustomerGroup1"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer Group 3</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomerGroup3"
              :options="optionCustomerGroup3"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              disabled
              @search-change="handlerSearchChangeCG3"
              :custom-label="customLableCustomerGroup1"
            />
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer</span>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomer"
              :options="optionCustomer"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              disabled
              @search-change="handlerSearchChange"
              :custom-label="customLableCustomer"
            />
          </div>
          <!-- <div class="vx-col sm:w-1/6 w-full text-right">
              <vs-button
                size="medium"
                class="mr-2 mb-1 bg-success"
                icon-pack="feather"
                icon="icon-search"
                @click="handleFindCustomer()"
                >refresh</vs-button
              >
            </div> -->
        </div>
      </div>
    </div>
    <hr />
    <br />
    <div class="vx-col sm:w-1/2 w-full mb-base">
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/4 w-full">
          <span>Source</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <multiselect
            class="selectExample"
            v-model="selectSource"
            :options="OptionSource"
            :multiple="false"
            :filterable="true"
            :allow-empty="true"
            :group-select="true"
            :max-height="100"
            :limit="3"
            :internal-search="false"
            placeholder="Type to search"
            :searchable="true"
            track-by="value"
            label="name"
            disabled
          />
        </div>
      </div>
    </div>
    <hr />

    <div class="vx-row" v-if="selectSource.value == 'SKU'">
      <div class="vx-col sm:w-1/1 w-full mb-base m-10">
        <table width="100%" class="vs-table vs-table--tbody-table">
          <thead class="vs-table--thead">
            <tr>
              <th width="30%">SKU Code</th>
              <th width="30%">SKU Name</th>
              <th width="25%" v-if="isAnyDefault">HU</th>
              <th width="30%" v-if="isAnyDefault">Min. Qty [SKU]</th>
              <th width="5%"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(child, index) in itemLines"
              :key="index"
              style="padding-bottom: 5px"
            >
              <td class="td vs-table--td" style="padding: 5px">
                <multiselect
                  class="selectExample"
                  v-model="itemLines[index].selected_items"
                  :options="optionItem"
                  :multiple="false"
                  :allow-empty="false"
                  :internal-search="false"
                  :group-select="false"
                  :max-height="160"
                  :limit="4"
                  placeholder=" Type to search"
                  track-by="item_id"
                  label="sku_code"
                  @search-change="onSearchItems($event)"
                />
              </td>
              <td class="td vs-table--td" style="padding: 5px">
                <span>
                  {{
                    itemLines[index].selected_items
                      ? itemLines[index].selected_items.item_name
                      : ""
                  }}
                </span>
              </td>
              <td class="td vs-table--td" style="padding: 5px">
                <vs-checkbox
                  color="success"
                  v-model="itemLines[index].isDefault"
                />
              </td>
              <td
                class="td vs-table--td"
                style="padding-right: 10px"
                v-if="child.isDefault"
              >
                <multiselect
                  v-if="itemLines[index].selected_items"
                  class="selectExample"
                  v-model="itemLines[index].selected_item_unit"
                  :options="itemLines[index].selected_items.hu_item_response"
                  :multiple="false"
                  :allow-empty="false"
                  :group-select="false"
                  :max-height="160"
                  :limit="10"
                  placeholder=" Type to search"
                  track-by="unit_id"
                  label="unit_name"
                />
              </td>
              <td
                class="td vs-table--td"
                style="padding-right: 10px"
                v-if="!child.isDefault"
              ></td>
              <td
                class="td vs-table--td"
                style="padding-right: 10px"
                v-if="!child.isDefault"
              ></td>

              <td
                class="td vs-table--td"
                style="padding-right: 10px"
                v-if="child.isDefault"
              >
                <vs-input
                  class="w-full"
                  type="number"
                  v-model="itemLines[index].qty"
                  :min="1"
                />
              </td>

              <td
                width="75px"
                class="td vs-table--td"
                style="text-align: center"
              >
                <div class="vx-input-group flex">
                  <vs-button
                    v-if="itemLines.length > 1"
                    @click.stop="removeRow(index)"
                    size="small"
                    color="danger"
                    icon-pack="feather"
                    icon="icon-trash"
                    style="margin-right: 5px"
                    title="Remove Row"
                  />

                  <vs-button
                    v-if="index == itemLines.length - 1"
                    @click.stop="addRow(index)"
                    size="small"
                    color="success"
                    icon-pack="feather"
                    icon="icon-plus"
                    title="Add Row"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="vx-row" v-if="selectSource.value == 'SKUCa'">
      <div class="vx-col sm:w-1/1 w-1/2 mb-base m-10">
        <table width="100%" class="vs-table vs-table--tbody-table">
          <thead class="vs-table--thead">
            <tr>
              <th width="30%">Item Category</th>
              <th width="30%">Name</th>
              <th width="5%"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(child, index) in itemCategoryLines"
              :key="index"
              style="padding-bottom: 5px"
            >
              <td class="td vs-table--td" style="padding: 5px">
                <multiselect
                  class="selectExample"
                  v-model="itemCategoryLines[index].selected_items"
                  :options="optionItemCategory"
                  :multiple="false"
                  :allow-empty="false"
                  :group-select="false"
                  :max-height="160"
                  :limit="4"
                  placeholder=" Type to search"
                  track-by="ID"
                  label="code"
                  @search-change="onSearchItemCategory($event, true)"
                  name="item"
                />
                <span class="text-danger text-sm" v-show="errors.has('item')">{{
                  errors.first("item")
                }}</span>
              </td>
              <td class="td vs-table--td" style="padding: 5px">
                <span>
                  {{
                    itemCategoryLines[index].selected_items
                      ? itemCategoryLines[index].selected_items.name
                      : ""
                  }}
                </span>
              </td>

              <td
                width="75px"
                class="td vs-table--td"
                style="text-align: center"
              >
                <div class="vx-input-group flex">
                  <vs-button
                    v-if="itemCategoryLines.length > 1"
                    @click.stop="removeRowItemCategory(index)"
                    size="small"
                    color="danger"
                    icon-pack="feather"
                    icon="icon-trash"
                    style="margin-right: 5px"
                    title="Remove Row"
                  />

                  <vs-button
                    v-if="index == itemCategoryLines.length - 1"
                    @click.stop="addRowItemCategory(index)"
                    size="small"
                    color="success"
                    icon-pack="feather"
                    icon="icon-plus"
                    title="Add Row"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  async mounted() {
    // console.log("mounted - form : ", this.territoryCode, this.territoryId, this.selectedReservation, this.optionTerritory);
    this.update = true;
    await this.getTerritory();
    this.ProductItem = this.$route.params.id;
    await this.getCustomerCategory();
    await this.getCustomerGroup1();
    await this.getCustomerGroup2();
    await this.getCustomerGroup3();
    await this.getProductItems(this.ProductItem);
    await this.getCustomer();
    await this.getPaymenTemp();
    await this.getSupplier();
    await this.getSoType();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
    isAnyDefault() {
      return this.itemLines.some((item) => item.isDefault);
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD MMM YYYY");
      }
    },
  },
  watch: {
    search: function () {
      if (this.optionItemAvailableShow) {
        this.getOptionItemAvailable();
      }
    },
    itemsQuantity: function () {
      this.quantityChecked = false;
    },
  },
  data: () => ({
    update: false,
    quantityChecked: false,
    external: false,

    optionItemAvailable: [{}],

    optionCustomerShow: false,
    optionItemAvailableShow: false,

    limits: [10, 25, 50, 100, "All"],
    limitShow: 10,
    drawData: 0,
    isActive: 1,
    recordsTotal: 0,
    maxPage: 2,
    dataEnd: 0,

    optionItem: [],
    itemLines: [
      {
        selected_items: null,
        option_item_unit: [],
        selected_item_unit: null,

        qty: 0,
        isDefault: false,
      },
    ],
    optionSoType: [],
    selectedSoType: [],
    optionTOP: [],
    selectedTOP: { name: "" },
    optionSupplier: [],
    selectedSupplier: { Name: "", Code: "", ID: 0 },
    optionTerritory: [],
    selectedTerritory: { code: "", name: "ALL", ID: 0 },
    optionDistributionChannel: [],
    selectedDistributionChannel: [],
    optionCustomerGroup1: [],
    selectedCustomerGroup1: [],
    optionCustomerGroup2: [],
    selectedCustomerGroup2: [],
    optionCustomerGroup3: [],
    selectedCustomerGroup3: [],
    optionCustomer: [],
    selectedCustomer: [],
    optionItemCategory: [],
    itemCategoryLines: [
      {
        selected_items: null,
      },
    ],
    start: 1,
    end: 0,
    length: 10,
    page: 1,
    search: "",
    order: "id",
    sort: "desc",
    total: 0,
    totalPage: 0,
    totalSearch: 0,
    ValidFr: "",
    ValidT: "",
    DateD: "",
    DayD: 0,
    NameN: "",
    date: "",
    description: "",
    code: "",
    companyID: 0,
    ProductItem: [],
    ID: 0,
    cartProducts: [],
    itemUnitID: [],
    selectedTopModel: "",
    createdBy: "",
    createdAt: "",
    approvedBy: "",
    dateApproved: "",
    OptionTopModel: [
      { name: "TOP", value: "TOP" },
      { name: "FDD", value: "FDD" },
    ],
    selectSource: { name: "SKU", value: "SKU" },
    OptionSource: [
      { name: "SKU", value: "SKU" },
      { name: "SKU Category", value: "SKUCa" },
      { name: "Supplier", value: "Sup" },
    ],
    status: true,
    notes: "",
  }),
  methods: {
    handleBack() {
      if (this.$route.params.is_approval == true) {
        this.$router.push({
          name: "Product Festives",
        });
      } else {
        this.$router.push({
          name: "product-festives",
        });
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },
    CancelForm() {
      this.update = true;
      this.quantityChecked = false;
      this.external = false;

      this.Input = this.resetReservation;

      this.cartProducts = [];

      this.itemsUom = [];
      this.itemsQuantity = [];

      this.optionItemAvailable = [{}];

      this.optionItemAvailableShow = false;

      this.$emit("selectReservation", {});
    },

    getOptionItemAvailable(index) {
      // this.isActive = page;
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/table", {
          params: {
            supplier_id: this.selectedSupplier.id,
            length: this.length,
            page: this.page,
            search: this.search,
            order: this.order,
            sort: this.sort,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionItem = resp.data.records;
            console.log("optionItem", this.optionItem);
            console.log("itemLines", this.itemLines);
            this.optionItem = this.optionItem.filter((el) => {
              let cek = this.itemLines.filter((el2) => {
                if (el2.selected_items) {
                  if (el2.selected_items.item_id == el.item_id) {
                    return true;
                  }
                }
                return false;
              });
              if (cek.length > 0) {
                return false;
              }
              return true;
            });
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Item Available",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    addRow(index) {
      this.itemLines.push({
        option_items: [],
        selected_items: null,
        option_item_unit: [],
      });

      this.getOptionItemAvailable(this.itemLines.length);
    },
    removeRow(index) {
      this.itemLines.splice(index, 1);
    },
    onChangeItems(val, index) {
      let cek = this.itemLines.filter((el, i) => {
        if (el.selected_items) {
          if (el.selected_items.id == val.id && i != index) {
            return true;
          }
        }
        return false;
      });

      if (cek.length > 0) {
        this.itemLines[index].selected_items = null;
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Sales already add",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }
      // console.log("val", val);

      this.itemLines[index].selected_items = val;
    },
    onSearchItems(search) {
      this.search = search;
      this.page = 1;
      this.getOptionItemAvailable();
    },
    getPaymenTemp() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/paymenTerm", {
          params: {
            length: 9999,
            order: "asc",
            sort: "name",
            search: this.searchTop,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              // this.optionTerritory = [{ code: "", name: "ALL", ID: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionTOP.push(resp.data.records[index]);
              }
              // this.optionTOP = resp.data.records;
              if (this.optionTOP.length > 0) {
                // this.selectedTOP = this.optionTOP[0];
              } else {
                this.optionTOP = [];
                this.selectedTOP = {};
              }
            } else {
              this.optionTOP = [];
              this.selectedTOP = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/territories", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
            search: this.searchT,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = [{ code: "", name: "ALL", ID: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionTerritory.push(resp.data.records[index]);
              }
              // this.optionTerritory = resp.data.records;
              if (this.optionTerritory.length > 0) {
                // this.selectedTerritory = this.optionTerritory[0];
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = { code: "", name: "ALL", ID: 0 };
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCustomerCategory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/customer-category", {
          params: {
            length: 999,
            sort: "name",
            order: "asc",
            search: this.searchCC,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionDistributionChannel = [
                { name: "ALL", classification: "", ID: 0 },
              ];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionDistributionChannel.push(resp.data.records[index]);
              }
              // this.optionDistributionChannel = resp.data.records;
              if (this.optionDistributionChannel.length > 0) {
                // this.selectedDistributionChannel =
                //   this.optionDistributionChannel[0];
              } else {
                console.log("BBBBBB");
                this.optionDistributionChannel = [];
                this.selectedDistributionChannel = {
                  code: "",
                  name: "ALL",
                  ID: 0,
                };
              }
            } else {
              this.optionDistributionChannel = [];
              this.selectedDistributionChannel = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCustomerGroup3() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/customer-group", {
          params: {
            length: 9999,
            order: "asc",
            sort: "name",
            level: 3,
            search: this.searchCG3,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionCustomerGroup3 = [{ code: "", name: "ALL", ID: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionCustomerGroup3.push(resp.data.records[index]);
              }
              // this.optionCustomerGroup3 = resp.data.records;
              if (this.optionCustomerGroup3.length > 0) {
                // this.selectedCustomerGroup3 = this.optionCustomerGroup3[0];
              } else {
                this.optionCustomerGroup3 = [];
                this.selectedCustomerGroup3 = { code: "", name: "ALL", ID: 0 };
              }
            } else {
              this.optionCustomerGroup3 = [];
              this.selectedCustomerGroup3 = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCustomerGroup2() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/customer-group", {
          params: {
            length: 9999,
            order: "asc",
            sort: "name",
            level: 2,
            search: this.searchCG2,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionCustomerGroup2 = [{ code: "", name: "ALL", ID: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionCustomerGroup2.push(resp.data.records[index]);
              }
              // this.optionCustomerGroup2 = resp.data.records;
              if (this.optionCustomerGroup2.length > 0) {
                // this.selectedCustomerGroup2 = this.optionCustomerGroup2[0];
              } else {
                this.optionCustomerGroup2 = [];
                this.selectedCustomerGroup2 = { code: "", name: "ALL", ID: 0 };
              }
            } else {
              this.optionCustomerGroup2 = [];
              this.selectedCustomerGroup2 = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCustomerGroup1() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/customer-group", {
          params: {
            length: 9999,
            order: "asc",
            sort: "name",
            level: 1,
            search: this.searchCG1,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionCustomerGroup1 = [{ code: "", name: "ALL", ID: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionCustomerGroup1.push(resp.data.records[index]);
              }
              // this.optionCustomerGroup1 = resp.data.records;
              if (this.optionCustomerGroup1.length > 0) {
                // this.selectedCustomerGroup1 = this.optionCustomerGroup1[0];
              } else {
                this.optionCustomerGroup1 = [];
                this.selectedCustomerGroup1 = { code: "", name: "ALL", ID: 0 };
              }
            } else {
              this.optionCustomerGroup1 = [];
              this.selectedCustomerGroup1 = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCustomer() {
      let DistributionChannelIDs = [];
      this.selectedDistributionChannel.forEach((element) => {
        if (element.ID != 0) {
          DistributionChannelIDs.push(element.ID);
        }
      });

      let CustomerGr1IDs = [];
      this.selectedCustomerGroup1.forEach((element) => {
        if (element.ID != 0) {
          CustomerGr1IDs.push(element.ID);
        }
      });

      let CustomerGr2IDs = [];
      this.selectedCustomerGroup2.forEach((element) => {
        if (element.ID != 0) {
          CustomerGr2IDs.push(element.ID);
        }
      });

      let CustomerGr3IDs = [];
      this.selectedCustomerGroup3.forEach((element) => {
        if (element.ID != 0) {
          CustomerGr3IDs.push(element.ID);
        }
      });

      let params = {
        length: 999,
        search: this.searchCust,
        customer_category_id: DistributionChannelIDs,
        customer_group_id_1: CustomerGr1IDs,
        customer_group_id_2: CustomerGr2IDs,
        customer_group_id_3: CustomerGr3IDs,
      };
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/customer", {
          params: {
            length: 10,
            order: "asc",
            sort: "name",
            search: this.searchCust,
            customer_category_id: DistributionChannelIDs,
            customer_group_id_1: CustomerGr1IDs,
            customer_group_id_2: CustomerGr2IDs,
            customer_group_id_3: CustomerGr3IDs,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionCustomer = [{ code: "", name: "ALL", ID: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionCustomer.push(resp.data.records[index]);
              }
              // this.optionCustomer = resp.data.records;
              if (this.optionCustomer.length > 0) {
                // this.selectedCustomer = this.optionCustomer[0];
              } else {
                this.optionCustomer = [];
                this.selectedCustomer = { code: "", name: "ALL", ID: 0 };
              }
            } else {
              this.optionCustomer = [];
              this.selectedCustomer = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getSoType() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/sales-order-type", {
          params: {
            length: 9999,
            search: this.searchCG1,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionSoType = resp.data.records;

              if (this.optionSoType.length > 0) {
                // this.selectedSoType = this.optionSoType[0];
              } else {
                this.optionSoType = [];
                this.selectedSoType = [];
              }
            } else {
              this.optionSoType = [];
              this.selectedSoType = [];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getSupplier() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/supplier", {
          params: {
            length: 10,
            search: this.searchSup,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionSupplier = resp.data.records;

              if (this.optionSupplier.length > 0) {
                // this.selectedSupplier = this.optionSupplier[0];
                this.optionSupplier = [
                  {
                    Code: "ALL",
                    Name: "",
                    ID: 0,
                  },
                  ...this.optionSupplier,
                ];
              } else {
                this.optionSupplier = [];
                this.selectedSupplier = {};
              }
            } else {
              this.optionSupplier = [];
              this.selectedSupplier = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    customLableTerritory({ code, name }) {
      return `${code} ${name}`;
    },
    customLableTOP({ name }) {
      return `${name}`;
    },
    customLableDistributionChannel({ name, classification }) {
      return `${name} ${classification}`;
    },
    customLableCustomer({ code, name }) {
      return `${code} ${name}`;
    },
    customLableCustomerGroup1({ code, name }) {
      return `${code} ${name}`;
    },
    customLableSoType({ Code }) {
      return `${Code}`;
    },
    customLableSupplier({ Code, Name }) {
      return `${Code}-${Name}`;
    },

    handleSearch(searching) {
      this.search = searching;
      this.page = 1;
      this.getOptionItemAvailable(this.isActive);
    },

    getProductItems(id) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/" + id + "/edit")
        .then((resp) => {
          if (resp.code == 200) {
            this.ID = resp.data.id;
            this.code = resp.data.code;
            this.description = resp.data.description;
            this.selectedTopModel = resp.data.top_model
              ? resp.data.top_model == "TOP"
                ? { name: "TOP", value: "TOP" }
                : { name: "FDD", value: "FDD" }
              : { name: "TOP", value: "TOP" };
            this.selectedSupplier.ID = resp.data.supplier_id
              ? resp.data.supplier_id
              : 0;
            this.selectedSupplier.Code = resp.data.supplier_code
              ? resp.data.supplier_code
              : "ALL";
            this.selectedSupplier.Name = resp.data.supplier_name
              ? resp.data.supplier_name
              : "";
            this.createdBy = resp.data.created_by;
            this.createdAt = resp.data.created_at;
            this.notes = resp.data.note;
            this.approvedBy = resp.data.apporved_by
              ? resp.data.apporved_by
              : "";
            if (this.approvedBy != "") {
              this.dateApproved = resp.data.updated_at;
            }

            this.date = resp.data.date
              ? moment(resp.data.date).format("YYYY-MM-DD")
              : "";
            this.validFrom = resp.data.valid_from
              ? moment(resp.data.valid_from).format("YYYY-MM-DD")
              : "";
            this.validTo = resp.data.valid_to
              ? moment(resp.data.valid_to).format("YYYY-MM-DD")
              : "";
            this.selectedTOP.name = resp.data.payment_term_name;
            this.selectedTOP.ID = resp.data.payment_term_id;

            this.status = resp.data.is_active;
            this.selectedSoType = resp.data.sales_order_type
              ? resp.data.sales_order_type.length > 0
                ? resp.data.sales_order_type
                : []
              : [];

            this.selectedTerritory = resp.data.territory
              ? resp.data.territory.length > 0
                ? resp.data.territory
                : [{ ID: 0, name: "ALL", code: "" }]
              : [{ ID: 0, name: "ALL", code: "" }];
            //grouped by level for customer Group, if level 1, then level 2 and 3
            //will be empty
            let customerGroup1 = resp.data.customer_group
              ? resp.data.customer_group.filter((el) => {
                  return el.level == 1;
                })
              : [];
            let customerGroup2 = resp.data.customer_group
              ? resp.data.customer_group.filter((el) => {
                  return el.level == 2;
                })
              : [];
            let customerGroup3 = resp.data.customer_group
              ? resp.data.customer_group.filter((el) => {
                  return el.level == 3;
                })
              : [];
            this.selectedCustomerGroup1 =
              customerGroup1.length > 0
                ? customerGroup1[0].code != ""
                  ? customerGroup1
                  : [{ ID: 0, name: "ALL", code: "" }]
                : [{ ID: 0, name: "ALL", code: "" }];
            this.selectedCustomerGroup2 =
              customerGroup2.length > 0
                ? customerGroup2[0].code != ""
                  ? customerGroup2
                  : [{ ID: 0, name: "ALL", code: "" }]
                : [{ ID: 0, name: "ALL", code: "" }];
            this.selectedCustomerGroup3 =
              customerGroup3.length > 0
                ? customerGroup3[0].code != ""
                  ? customerGroup3
                  : [{ ID: 0, name: "ALL", code: "" }]
                : [{ ID: 0, name: "ALL", code: "" }];

            this.selectedDistributionChannel = resp.data.customer_category
              ? resp.data.customer_category.length > 0
                ? resp.data.customer_category
                : [{ ID: 0, name: "ALL", classification: "" }]
              : [{ ID: 0, name: "ALL", classification: "" }];

            this.selectedCustomer = resp.data.customer
              ? resp.data.customer.length > 0
                ? resp.data.customer
                : [{ ID: 0, name: "ALL", code: "" }]
              : [{ ID: 0, name: "ALL", code: "" }];

            this.itemCategoryLines = [];
            if (resp.data.item_category != null) {
              resp.data.item_category.forEach((el) => {
                this.itemCategoryLines.push({
                  selected_items: el,
                });
              });
            } else {
              this.itemCategoryLines.push({
                selected_items: null,
              });
            }

            this.itemLines = [];
            console.log("resp item data", resp.data.item_sku);
            if (resp.data.item_sku != null) {
              resp.data.item_sku.forEach((el) => {
                this.itemLines.push({
                  selected_item_unit: el.hu_item_selected,
                  selected_items: el,
                  qty: el.qty,
                  option_item_unit: el.hu_item_response,
                  isDefault: el.qty != 0 ? true : false,
                });
              });
            } else {
              this.itemLines.push({
                option_items: [],
                selected_items: null,
                option_item_unit: [],
                qty: 0,
                isDefault: false,
              });
            }

            if (resp.data.source == 1) {
              this.getOptionItemAvailable();
            } else {
              this.getOptionItemCategory();
            }
          }
        });
      this.$vs.loading.close();
    },
    clearDay() {
      this.selectedTOP = {};
    },
    clearDate() {
      this.DateD = "";
    },
    handlerSearchChange(search) {
      this.optionCustomer = [];
      this.searchCust = search;
      this.getCustomer();
    },
    handlerSearchChangeCG3(searching) {
      this.optionCustomerGroup3 = [];
      this.searchCG3 = searching;
      this.getCustomerGroup3();
    },
    handlerSearchChangeCG2(searching) {
      this.optionCustomerGroup2 = [];
      this.searchCG2 = searching;
      this.getCustomerGroup2();
    },
    handlerSearchChangeCG1(searching) {
      this.optionCustomerGroup1 = [];
      this.searchCG1 = searching;
      this.getCustomerGroup1();
    },
    handlerSearchChangeCC(searching) {
      this.optionDistributionChannel = [];
      this.searchCC = searching;
      this.getCustomerCategory();
    },
    handlerSearchChangeT(searching) {
      this.optionTerritory = [];
      this.searchT = searching;
      this.getTerritory();
    },
    handlerSearchChangeTOP(searching) {
      this.optionTerritory = [];
      this.searchTop = searching;
      this.getPaymenTemp();
    },
    handleFindCustomer() {
      this.getCustomer();
    },
    SupplierInput() {
      this.itemLines = [
        {
          option_items: [],
          selected_items: null,
          option_item_unit: [],
          selected_item_unit: null,
          qty: [],
        },
      ];
      this.getOptionItemAvailable();
    },
    getOptionItemCategory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/item-category", {
          params: {
            length: 100,
            order: "asc",
            sort: "id",
            search: this.searchIC,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionItemCategory = resp.data.records;
            } else {
              this.optionItemCategory = [];
              this.selectedCategory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    addRowItemCategory(index) {
      if (!this.itemCategoryLines[index].selected_items) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Item Line first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      } else {
        this.itemCategoryLines.push({
          option_items: [],
          selected_items: null,
          option_item_unit: [],
        });

        this.getOptionItemCategory(this.itemCategoryLines.length);
      }
    },
    removeRowItemCategory(index) {
      this.itemCategoryLines.splice(index, 1);
    },
    onSearchItemCategory(search) {
      this.searchIC = search;
      this.page = 1;
      this.getOptionItemCategory();
    },
  },
};
</script>
